<template>
    <div>
        <!-- 导航栏 -->
        <header class="flex-col header-navbar sumWidth">
            <div class="headerLeft flex-col">
                <img src="../assets/materialLibrarylogo.png" alt="" class="materialLibrarylogo">
                <el-select v-model="value" class="m-2" placeholder="Select" size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="value1" class="m-2" placeholder="Select" size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="value2" class="m-2" placeholder="Select" size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <div class="hot">
                    热门推荐
                    <img src="../assets/hot.png">
                </div>
            </div>
            <div class="headerRight flex-col">
                <div :class="headerRightColor == 1 ? 'selectDivColor' : ''" @click="headerRightColor = 1">登录</div>
                <div :class="headerRightColor == 2 ? 'selectDivColor' : ''" @click="headerRightColor = 2">注册</div>
            </div>
        </header>
        <main>
            <!-- 轮播 -->
            <div class="swiper">
                <div class="swiperBox" id="swiper" :style="{ height: scrollerHeight }">
                    <el-carousel>
                        <el-carousel-item v-for="(item, index) in ppttrack" :key="index">
                            <a :href="item.url" target="_blank" v-if="item.type == 2">
                                <img class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                            </a>
                            <img class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- search -->
                <div class="mt-4">
                    <el-input v-model="input3" placeholder="请输入搜索关键词" class="input-with-select">
                        <template #prepend>
                            <el-select v-model="select" style="width: 115px">
                                <el-option label="Restaurant" value="1" />
                                <el-option label="Order No." value="2" />
                                <el-option label="Tel" value="3" />
                            </el-select>
                        </template>
                        <template #append>
                            <el-button icon="el-icon-search"></el-button>
                            搜索
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="content-top flex-col sumWidth">
                <div class="box">
                    <div class="titleAndImg flex-col">
                        <img src="../assets/教务处.png" alt="">
                        <div>
                            <p>教务处赛项</p>
                            <i></i>
                        </div>
                    </div>
                    <img src="../assets/tu-1.png">
                    <p>
                        设计师推荐是素材库专门服务于拥有更高参赛需求师生的平台。由在创赛领域深耕3年+的创赛老师、BP美化师联合打造，让模版内容更贴合大赛项目、模版设计更符合大赛审美，降低了您在撰写BP过程中的学习与应用成本，致力于成为您创赛路演中的得力助手。
                    </p>
                </div>
                <div class="box">
                    <div class="titleAndImg flex-col">
                        <img src="../assets/团委.png" alt="">
                        <div>
                            <p>团委赛项</p>
                            <i style="background: linear-gradient(90deg, #FBB32F, #EF3F26);"></i>
                        </div>
                    </div>
                    <img src="../assets/tu-2.png">
                    <p>
                        设计师推荐是素材库专门服务于拥有更高参赛需求师生的平台。由在创赛领域深耕3年+的创赛老师、BP美化师联合打造，让模版内容更贴合大赛项目、模版设计更符合大赛审美，降低了您在撰写BP过程中的学习与应用成本，致力于成为您创赛路演中的得力助手。
                    </p>
                </div>
                <div class="box">
                    <div class="titleAndImg flex-col">
                        <img src="../assets/学工处.png" alt="">
                        <div>
                            <p>学工部赛项</p>
                            <i style="background: linear-gradient(90deg, #FC8277, #FF6155);"></i>
                        </div>
                    </div>
                    <img src="../assets/tu-3.png">
                    <p>
                        设计师推荐是素材库专门服务于拥有更高参赛需求师生的平台。由在创赛领域深耕3年+的创赛老师、BP美化师联合打造，让模版内容更贴合大赛项目、模版设计更符合大赛审美，降低了您在撰写BP过程中的学习与应用成本，致力于成为您创赛路演中的得力助手。
                    </p>
                </div>
            </div>
            <div style="background:#F7F6F6;">
                <!-- 推荐PPT -->
                <div class="content-center sumWidth">
                    <div class="content-title flex-col">
                        <div class="flex-col">
                            <img src="../assets/PPT.png" alt="">
                            <div class="content-name">
                                <div>推荐PPT</div>
                                <div>PPT RECOMMEND</div>
                            </div>
                        </div>
                        <div class="circle">
                            <span :class="pptSlectCircle == 0 ? 'opactySelect' : ''" @click="pptTransfrom(0)"></span>
                            <span :class="pptSlectCircle == 1 ? 'opactySelect' : ''" @click="pptTransfrom(1)"></span>
                            <span :class="pptSlectCircle == 2 ? 'opactySelect' : ''" @click="pptTransfrom(2)"></span>
                        </div>
                    </div>
                    <div class="flex-col ppt-box">
                        <div id="ppt-box-transform">
                            <!-- <div class="ppt-swiper">
                            <img src="" alt="">
                            <p>职教类医疗浅绿风</p>
                            <div class="ppt-foot flex-col">
                                <div>
                                    <span>高教主赛道</span>
                                    <span>新医科</span>
                                </div>
                                <div class="ppt-ico flex-col">
                                    <em>
                                        <i class="iconfont iconai-eye"></i>1515
                                    </em>
                                    <em>
                                        <i class="iconfont iconaixin"></i>897
                                    </em>
                                </div>
                            </div>
                        </div> -->
                            <div class="ppt-swiper" v-for="(item, index) in artiList.slice(0, 12)" :key="index">
                                <img :src="item.cover" alt="">
                                <p>{{ item.name }}</p>
                                <div class="ppt-foot flex-col">
                                    <div>
                                        <span v-for="(citem, cindex) in item.tag.split(',')" :key="cindex">{{ citem
                                        }}</span>
                                    </div>
                                    <div class="ppt-ico flex-col">
                                        <em>
                                            <i class="iconfont iconai-eye"></i>{{ item.pv }}
                                        </em>
                                        <em>
                                            <i class="iconfont iconaixin"></i>{{ item.laud }}
                                        </em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 推荐WORD -->
                <div class="content-center sumWidth" style="padding-bottom: 80px;">
                    <div class="content-title flex-col">
                        <div class="flex-col">
                            <img src="../assets/word1.png" alt="">
                            <div class="content-name">
                                <div>推荐WORD</div>
                                <div>WORD RECOMMEND</div>
                            </div>
                        </div>
                        <div class="circle">
                            <span :class="wordSlectCircle == 0 ? 'opactySelect' : ''" @click="wordTransfrom(0)"></span>
                            <span :class="wordSlectCircle == 1 ? 'opactySelect' : ''" @click="wordTransfrom(1)"></span>
                            <span :class="wordSlectCircle == 2 ? 'opactySelect' : ''" @click="wordTransfrom(2)"></span>
                        </div>
                    </div>
                    <div class="flex-col ppt-box">
                        <div id="word-box-transform">
                            <!-- <div class="ppt-swiper">
                            <img src="" alt="">
                            <p>职教类医疗浅绿风</p>
                            <div class="ppt-foot flex-col">
                                <div>
                                    <span>高教主赛道</span>
                                    <span>新医科</span>
                                </div>
                                <div class="ppt-ico flex-col">
                                    <em>
                                        <i class="iconfont iconai-eye"></i>1515
                                    </em>
                                    <em>
                                        <i class="iconfont iconaixin"></i>897
                                    </em>
                                </div>
                            </div>
                        </div> -->
                            <div class="ppt-swiper" v-for="(item, index) in wordList.slice(0, 12)" :key="'info-' + index">
                                <img :src="item.cover" alt="">
                                <p>{{ item.name }}</p>
                                <div class="ppt-foot flex-col">
                                    <div>
                                        <span v-for="(citem, cindex) in item.tag.split(',')" :key="cindex">{{ citem
                                        }}</span>
                                    </div>
                                    <div class="ppt-ico flex-col">
                                        <em>
                                            <i class="iconfont iconai-eye"></i>{{ item.pv }}
                                        </em>
                                        <em>
                                            <i class="iconfont iconaixin"></i>{{ item.laud }}
                                        </em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 定制服务 -->
            <div style="padding: 80px 0;" class="sumWidth">
                <div class="content-title flex-col">
                    <div class="flex-col">
                        <img src="../assets/定制服务.png" alt="">
                        <div class="content-name">
                            <div>定制服务</div>
                            <div>Customized services</div>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div class="customizedService-box flex-col">
                    <div class="customizedService">
                        <img src="../assets/美化.png" alt="">
                        <p>项目BP美化定制</p>
                    </div>
                    <div class="customizedService">
                        <img src="../assets/plus.png" alt="">
                        <p>PLUS多用途定制</p>
                    </div>
                    <div class="customizedService">
                        <img src="../assets/代写.png" alt="">
                        <p>商业计划书代写</p>
                    </div>
                    <div class="customizedService">
                        <img src="../assets/VCR1.png" alt="">
                        <p>项目VCR定制</p>
                    </div>
                </div>
            </div>
            <!-- al抠图 -->
            <div class="sumWidth">
                <div class="AlTitle">
                    <p>AI自动抠图，体验从未有过的简单</p>
                    <p>一键上传，自动抠图，完美呈现</p>
                </div>
                <div class="Al-content flex-col">
                    <div class="Al-box">
                        <p>人像</p>
                        <p>自拍照、证件照、旅行照、写真照...各种人像场景，通通搞定</p>
                        <div class="peoper">
                            <div></div>
                            <div></div>
                            <img src="../assets/人像.png" alt="">
                        </div>
                    </div>
                    <div class="Al-box">
                        <p>电商产品</p>
                        <p>服饰鞋包、美妆、汽车、玩具、美食...轻松清除背景。</p>
                        <div class="peoper">
                            <div></div>
                            <div></div>
                            <img src="../assets/化妆品.png" class="Aldog">
                        </div>
                    </div>
                    <div class="Al-box">
                        <p>动植物、图形</p>
                        <p>各类动、植物祛除复杂背景，形状、logo、图标均可使用。</p>
                        <div class="peoper">
                            <div></div>
                            <div></div>
                            <img src="../assets/狗子.png" class="Aldog">
                        </div>
                    </div>
                </div>
                <button class="ExperienceRightAway">马上体验</button>

            </div>
        </main>
        <footer style="background-color: #000000;">
            <div class="swiper">
                <img src="../assets/banner-2.png" alt="">
                <!-- search -->
                <div class="mt-4">
                    <el-input v-model="input3" placeholder="Please input" class="input-with-select">
                        <template #prepend>
                            <el-select v-model="select" placeholder="Select" style="width: 115px">
                                <el-option label="Restaurant" value="1" />
                                <el-option label="Order No." value="2" />
                                <el-option label="Tel" value="3" />
                            </el-select>
                        </template>
                        <template #append>
                            <el-button icon="el-icon-search"></el-button>
                            搜索
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="footer-center flex-col">
                <div class="footer-box">
                    <img src="../assets/版权.png" alt="">
                    <p>正版承诺</p>
                    <p>拒绝盗版，获设计师独家授权</p>
                </div>
                <div class="footer-box">
                    <img src="../assets/商用.png" alt="">
                    <p>版权保障 商用无忧</p>
                    <p>对全站所有模板均拥有商用授权</p>
                </div>
                <div class="footer-box">
                    <img src="../assets/服务.png" alt="">
                    <p>全天候 贴心服务</p>
                    <p>发票支持，报销无忧 <br>团队协作，院校会员可多人使用。</p>
                </div>
            </div>
            <div class="footer-bottom flex-col">
                <img src="../assets/logo -白.png" alt="">
                <div>
                    <img src="../assets/联系我们.png" alt="">
                    <p>联系我们</p>
                </div>
                <div>
                    <img src="../assets/关于我们.png" alt="">
                    <p>关于我们</p>
                </div>
                <div>
                    <img src="../assets/用户协议.png" alt="">
                    <p>用户协议</p>
                </div>
                <div>
                    <img src="../assets/加入我们.png" alt="">
                    <p>加入我们</p>
                </div>
                <div>
                    <img src="../assets/订制服务.png" alt="">
                    <p>订制服务</p>
                </div>
            </div>
            <div class="foot">
                <p>陕公网安备61019002001772号陕ICP备2022004291号</p>
                <p>Copyright © 2024 陕西重构教育科技有限公司，All Rights Reserved.</p>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: '教务处赛项',
            value1: '团委赛项',
            value2: '学工部赛项',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1',
                },
                {
                    value: 'Option2',
                    label: 'Option2',
                },
                {
                    value: 'Option3',
                    label: 'Option3',
                },
                {
                    value: 'Option4',
                    label: 'Option4',
                },
                {
                    value: 'Option5',
                    label: 'Option5',
                },
            ],
            headerRightColor: 1,  //默认登录选中
            pptSlectCircle: 0,
            wordSlectCircle: 0,
            input3: '',
            select: 'BP演示版',
            ppttrack: [],
            artiList: [],
            wordList: [],
            screenWidth: document.body.clientWidth,
            scrollerHeight: 0 + 'px',
        }
    },
    methods: {
        getapp() {
            //获取轮播图
            this.axios.indexAd({
            }).then(res => {
                if (res.data.code == 0) {
                    this.ppttrack = res.data.data;
                    this.swiperBox();
                } else {
                    this.ppttrack = []
                }
            }).catch(err => {

            })
            this.axios.indexPage({

            }).then(res => {
                if (res.data.code == 0) {
                  
                    this.artiList = res.data.data.ppt;
                    this.wordList = res.data.data.word;
                    this.swiperBox();
                }
            }).catch(err => {

            })
        },
        swiperBox() {
            const that = this
            var height = document.getElementById('imagBox').offsetHeight;
            that.scrollerHeight = height + 'px'
        },
        timer() {
            const that = this
            setInterval(function () {
                that.pptSlectCircle == 2 ? that.pptSlectCircle = 0 : that.pptSlectCircle++
                that.wordSlectCircle == 2 ? that.wordSlectCircle = 0 : that.wordSlectCircle++
                that.pptTransfrom(that.pptSlectCircle)
                that.wordTransfrom(that.wordSlectCircle)
            }, 3000)
        },
        addUrl(item) {
            
        },
        pptTransfrom(val) {
            this.pptSlectCircle = val
            var offleft = document.getElementById('ppt-box-transform')
            // offleft.style.translate = '2s'
            offleft.style.transform = `translateX(-${val * 1385}px)`

        },
        wordTransfrom(val) {
            this.wordSlectCircle = val
            var offleft = document.getElementById('word-box-transform')
            offleft.style.transform = `translateX(-${val * 1385}px)`

        }
    },
    watch: {
        screenWidth(val) {
            this.swiperBox()
        }
    },
    mounted() {
        this.getapp()
        this.timer()
        this.$nextTick(() => {
            setTimeout(() => {
                this.swiperBox()
            }, 1000)
        })
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    }
}

</script>
<style scoped>
* {
    font-size: 16px;
    user-select: none;
}

.sumWidth {
    width: 1400px;
    margin: auto;
}

.flex-col {
    display: flex;
}

.header-navbar {
    height: 32px;
    justify-content: space-between;
    margin: 20px auto;
    padding-top: 20px;
}

.headerLeft {
    display: flex;
    align-items: center;
}

.materialLibrarylogo {
    width: 92px;
    height: 30px;
}

.hot {
    width: 120px;
    line-height: 30px;
    font-weight: 500;
    color: #333333;
    margin-left: 30px;
    cursor: pointer;
}

.hot>img {
    width: 18px;
    height: 22px;
    margin-left: -10px;

}

.headerRight>div {
    width: 100px;
    height: 32px;
    border: 1px solid #999999;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin-right: 20px;
}

.headerRight>.selectDivColor {
    border-color: #30A7EE;
    color: #30A7EE;
}

.swiper {
    min-width: 1400px;
    position: relative;
}

.swiper img {
    width: 100%;
}

.mt-4 {
    width: 680px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.content-top {
    margin: 70px auto 45px;
    justify-content: space-between;
}

.box {
    width: 400px;
}

.titleAndImg {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.titleAndImg>img {
    width: 10%;
    margin-right: 8px;
}

.titleAndImg>div {
    width: 45%;
    height: 37px;
    position: relative;
}


.titleAndImg p {
    width: 100%;
    height: 37px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    position: absolute;
    text-align: center;
    z-index: 99;
}

.titleAndImg i {
    display: inline-block;
    width: 100%;
    height: 6px;
    background: linear-gradient(90deg, #28D4E1, #13A4E6);
    position: absolute;
    bottom: 6px;
}

.box:nth-of-type(2) {
    margin: 0 20px;
}

.box>img {
    width: 100%;
    height: 225px;
    margin: 13px 0 10px 0;
}

.box>p {
    width: 100%;
    height: 119px;
    text-indent: 2rem;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
    overflow: hidden;
}

.content-center {
    background: #F7F6F6;
}

.content-title {
    padding-top: 45px;
    margin: 0 2% 32px 2%;
    justify-content: space-between;
}

.content-title img {
    width: 67px;
    height: 67px;
    margin-right: 20px;
}

.content-name>div:nth-of-type(1) {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.content-name>div:nth-of-type(2) {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #D24625;
}

.ppt-box {
    margin: 0 0 0 2%;
    overflow: hidden;
    justify-content: space-between;
    cursor: pointer;
}

.ppt-box>div {
    display: flex;
}

.ppt-swiper {
    width: 326px;
    min-width: 326px;
    background: #FFFFFF;
    box-shadow: 0px 6px 9px 1px rgba(75, 74, 74, 0.3);
    border-radius: 4px;
    overflow: auto;
    margin-right: 20px;
}

.ppt-swiper:last-child {
    margin: 0;
}

.ppt-swiper>img {
    width: 100%;
    height: 215px;
}

.ppt-swiper>p {
    height: 26px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    border-bottom: 1px solid #E4E4E4;
    margin: 0 10px;
}

.ppt-foot {
    margin: 10px;
    justify-content: space-between;
}

.ppt-foot span {
    display: inline;
    padding: 3px 8px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #EE5400;
    line-height: 26px;
    margin-right: 20px;
    background: #FFF4EB;
}

.ppt-ico {
    justify-content: center;
    align-items: center;
}

.ppt-foot em {
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-left: 14px;
}

.circle>span {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #1F9FDB;
    opacity: 0.5;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
}

.circle>.opactySelect {
    opacity: 1;
}

.customizedService-box {
    /* width: 1000px; */
    justify-content: space-evenly;
}

.customizedService {
    width: 159px;
}

.customizedService>img {
    width: 159px;
    height: 159px;
}

.customizedService>p {
    width: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
}

.AlTitle {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
}

.AlTitle>p:nth-of-type(1) {
    width: 100%;
    height: 38px;
    font-size: 38px;
}

.AlTitle>p:nth-of-type(2) {
    width: 100%;
    height: 20px;
    font-size: 20px;
    margin: 33px 0 50px 0;
}

.Al-content {
    justify-content: space-evenly;
}

.Al-box {
    box-sizing: border-box;
    width: 440px;
    height: 610px;
    background: #D4EFFF;
    border-radius: 4px;
    padding-left: 47px;
    padding-top: 43px;
    margin-right: 40px;
}

.Al-box:last-child {
    margin-right: 0;
}

.Al-box>p:nth-of-type(1) {
    width: 100%;
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bolder;
    color: #333333;
}

.Al-box>p:nth-of-type(2) {
    width: 331px;
    height: 37px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 29px 0 34px 0;
}

.peoper {
    display: flex;
    width: 347px;
    height: 389px;
}

.peoper>div:nth-of-type(1) {
    width: 174px;
    height: 389px;
    background-color: rgb(20, 20, 161);
}

.peoper>div:nth-of-type(2) {
    width: 174px;
    height: 389px;
    background-image: url(../assets/马赛克.png);
}

.Al-box img {
    width: 239px;
    height: 381px;
    position: absolute;
    margin: 8px 0 0 54px;
}

.Al-box .Aldog {
    width: 338px;
    height: 242px;
    margin: 74px 0 0 0;
}

.ExperienceRightAway {
    width: 120px;
    height: 40px;
    background: #2FA4F5;
    border-radius: 2px;
    color: #fff;
    border: 0;
    margin: 66px 0 90px 0;
    position: relative;
    left: calc(50% - 60px);
}

.footer-center {
    margin-top: 37px;
    justify-content: space-evenly;
}

.footer-box {
    width: 210px;
    text-align: center;
}

.footer-box>img {
    width: 51px;
    height: 51px;
}

.footer-box>p:nth-of-type(1) {
    width: 100%;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FEFEFE;
    margin: 2px 0 12px 0;
}

.footer-box>p:nth-of-type(2) {
    width: 100%;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FEFEFE;
    margin-bottom: 67px;
}

.footer-bottom {
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 30px;
}

.footer-bottom>img {
    width: 92px;
    height: 30px;
}

.footer-bottom>div {
    width: 56px;
    text-align: center;
}

.footer-bottom p {
    width: 56px;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.foot {
    text-align: center;
    padding-bottom: 26px;
}

.foot>p {
    font-size: 12px;
    color: #999999;
}
</style>

<style>
.el-input--small .el-input__inner {
    width: 130px;
    font-size: 16px;
    color: #333333;
    line-height: 26px;
    margin-left: 30px;
}

.el-input__inner {
    border: 0px;
}

.el-input-group--append .el-input__inner,
.el-input-group__prepend {
    height: 42px;
}

.input-with-select {
    width: 680px;
    background: #FFFFFF;
    box-shadow: 0px 6px 7px 0px rgba(75, 74, 74, 0.21);
    opacity: 0.95;
    border-radius: 25px;
    overflow: hidden;
}

.el-input-group__append {
    width: 140px;
    height: 42px;
    background: linear-gradient(270deg, #4FACFE, #00F2FE);
    border-radius: 21px;
    color: #FEFEFE;
    text-align: center;
    cursor: pointer;
}

.seamless-warp {
    height: 80px;
    margin-bottom: 17px;
    overflow: hidden;
}

.swiperBox .el-carousel__indicators {
    opacity: 0;
}

.swiperBox .el-carousel {
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.swiperBox .el-carousel__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>